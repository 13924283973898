"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contract = void 0;
var errors_1 = require("./errors");
var util_1 = require("./util");
function Contract() {
    var runtypes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        runtypes[_i] = arguments[_i];
    }
    var lastIndex = runtypes.length - 1;
    var argRuntypes = runtypes.slice(0, lastIndex);
    var returnRuntype = runtypes[lastIndex];
    return {
        enforce: function (f) { return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (args.length < argRuntypes.length) {
                var message = "Expected ".concat(argRuntypes.length, " arguments but only received ").concat(args.length);
                var failure = util_1.FAILURE.ARGUMENT_INCORRECT(message);
                throw new errors_1.ValidationError(failure);
            }
            for (var i = 0; i < argRuntypes.length; i++)
                argRuntypes[i].check(args[i]);
            return returnRuntype.check(f.apply(void 0, __spreadArray([], __read(args), false)));
        }; },
    };
}
exports.Contract = Contract;
