"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Void = void 0;
var unknown_1 = require("./unknown");
/**
 * Void is an alias for Unknown
 *
 * @deprecated Please use Unknown instead
 */
exports.Void = unknown_1.Unknown;
